<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <layers-navigation-tabs :selectedTab.sync="selectedTabNavegationTabs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <layers-data-table
          ref="layersDataTable"
          :titulo="tituloTabela"
          :items="items"
          :loading="loading"
          @rechargeTable="chargeTableDecision(tipoListaRelatorio)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RelatoriosService from '@/services/RelatoriosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],

  components: {
    LayersNavigationTabs: () =>
      import('@/components/layers-personalizados/LayersNavigationTabs'),
    LayersDataTable: () =>
      import('@/components/layers-personalizados/LayersDataTable')
  },

  data: () => ({
    items: [],
    tituloTabela: 'Layers',
    selectedTabNavegationTabs: 0,
    arrTour: [
      {
        element: '#tab-historico',
        intro:
          'Selecionando este menu você poderá acessar todos os layers cadastrados.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#tab-favoritos',
        intro:
          'Selecionando esta opção você poderá acessar os layers que você definir como seus favoritos.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#btn-novo-relatorio',
        intro:
          'Clicando neste botão você poderá efetuar consultas nas bases de dados geográficas, que servirão como fonte de dados para a configuração das simbologias dos layers.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#relatorios-historico-search',
        intro: 'Neste campo você poderá pesquisar por layers já cadastrados.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#numero-feicoes',
        intro:
          'Aqui você consegue visualizar o número de feições geográficas retornadas após o processamento do layer.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#action-visualizar',
        intro: 'Nesta opção você poderá visualizar o layer em um mapa.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-download-rel',
        intro: 'Aqui você pode baixar os dados da consulta em um arquivo XLSX.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-edit-rel',
        intro:
          'Clicando sobre este botão você poderá editar a consulta do seu layer, incluindo, alterando ou excluindo campos e filtros de dados.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-simbologia-rel',
        intro:
          'Aqui você poderá configurar a simbologia do layer, podendo configurar cores, ícones, fazer classificação dos dados com base em algum campo do layer ou criar um mapa de calor.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-favoritar-rel',
        intro:
          'Clicando sobre este botão você poderá marcar este layer como favorito, podendo acessá-lo mais rapidamente através do menu superior "FAVORITOS".',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-more-rel',
        intro:
          'Aqui você acessa outras opções para seu layer, como duplicá-lo, deletá-lo e é possivel visualizar informações de criação.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),

  created() {
    this.chargeTableDecision(this.tipoListaRelatorio);
  },

  computed: {
    tipoListaRelatorio() {
      return this.$store.getters.getTipoListaLayer;
    }
  },

  methods: {
    getRelatorios() {
      this.items = [];
      this.loading = true;
      RelatoriosService.index(1)
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    getRelatoriosFavoritados() {
      this.items = [];
      this.loading = true;
      RelatoriosService.getRelatoriosFavoritados(1)
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    chargeTableDecision(newDecisionInfo) {
      if (this.$refs.layersDataTable) {
        this.$refs.layersDataTable.page = 1;
      }
      this.tituloTabela = newDecisionInfo.titulo;

      switch (newDecisionInfo.tipo) {
        case 'favoritos':
          this.selectedTabNavegationTabs = 1;
          this.cancelRefreshData();
          this.refreshData(this.getRelatoriosFavoritados);
          break;
        case 'historico':
        default:
          this.selectedTabNavegationTabs = 0;
          this.cancelRefreshData();
          this.refreshData(this.getRelatorios);
          break;
      }
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 4);
      this.iniciarTour(arrTourFormatted);
    }
  },

  watch: {
    tipoListaRelatorio(newValue) {
      this.chargeTableDecision(newValue);
    }
  }
};
</script>
